<template>
  <div>
    <CRow class="mt-3">
      <CCol md="12" class="text-center">
        <h2 class="font-weight-bold text-black">
          ตั้งค่าอีเมลแจ้งเตือนคลังสินค้า
        </h2>
        <p>
          ตั้งค่าอีเมลสำหรับแจ้งเตือนคลังสินค้าเพื่อการจัดการงานคลังสินค้าได้สะดวกยิ่งขึ้น
        </p>
      </CCol>
    </CRow>
    <hr />
    <CCard>
      <CCardBody>
        <CCard>
          <CCardBody border-color="light">
            <table style="width:100%">
              <tr class="font-weight-bold h5">
                <td style="width: auto;" class="text-left d-flex">
                  <CIcon name="cil-settings" size="2xl" class="text-success" />
                  <p class="pl-2 my-auto text-grey">
                    {{ $t('usabilityStatus') }}
                  </p>
                </td>
                <td
                  v-if="stockMonitor === true"
                  class="text-right text-success"
                >
                  {{ $t('enable') }}
                  <h6
                    v-if="loadingStatus === true"
                    style="font-size:12px"
                    class="text-light"
                  >
                    {{ $t('loading') }} <CSpinner color="light" size="sm" />
                  </h6>
                </td>
                <td
                  v-else-if="stockMonitor === false"
                  class="text-right text-danger"
                >
                  {{ $t('disable') }}
                  <h6
                    v-if="loadingStatus === true"
                    style="font-size:12px"
                    class="text-light"
                  >
                    {{ $t('loading') }} <CSpinner color="light" size="sm" />
                  </h6>
                </td>
                <td style="width:5%" class="text-right">
                  <CSwitch
                    color="success"
                    :checked.sync="stockMonitor"
                    @update:checked="updateStatus($event)"
                  />
                </td>
              </tr>
            </table>
          </CCardBody>
        </CCard>

        <CAlert color="success" v-if="isSuccess === true" :show="Number(3)">
          {{ $t('saveSuccess') }}
        </CAlert>

        <form class="mt-4">
          <div class="form-group">
            <label for="exampleInputEmail1" class="font-weight-bold">
              ตัวเลือกแจ้งเตือน
            </label>
            <div class="custom-control custom-checkbox">
              <input
                id="sku"
                type="checkbox"
                v-model="skuStock"
                class="custom-control-input success cursor"
              />
              <label class="custom-control-label cursor" for="sku">
                สินค้าคงเหลือตามขนาด SKU
              </label>
            </div>
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input success cursor"
                type="checkbox"
                v-model="pluStock"
                id="plu"
              />
              <label class="custom-control-label cursor" for="plu">
                สินค้าคงเหลือตามขนาดบรรจุ
              </label>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1" class="font-weight-bold">
              {{ $t('email') }}
            </label>
            <input
              type="email"
              class="form-control"
              placeholder="อีเมล"
              :value="email"
              disabled
            />
          </div>
          <div class="form-group">
            <label class="font-weight-bold">จำนวนคงเหลือ</label>
            <input
              v-if="loadingButton === false"
              type="number"
              class="form-control"
              placeholder="จำนวนคงเหลือ"
              v-model="minStock"
            />
            <input
              v-else-if="loadingButton === true"
              type="number"
              class="form-control"
              placeholder="จำนวนคงเหลือ"
              :value="minStockUpdate"
              disabled
            />
            <small class="form-text text-muted">
              กำหนดจำนวนคงเหลือ สำหรับการส่งอีเมลแจ้งเตือน
            </small>
          </div>

          <button
            class="btn btn-success btn-block"
            @click="updateData()"
            v-if="loadingButton === false"
          >
            {{ $t('save') }}
          </button>
          <button
            class="btn btn-success btn-block"
            v-else-if="loadingButton === true"
            disabled
          >
            {{ $t('loading') }} <CSpinner size="sm" color="white" />
          </button>
        </form>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/local'

export default {
  data() {
    return {
      emailNotification: true,
      skuStockUpdate: false,
      pluStockUpdate: false,
      minStockUpdate: 0,
      loadingButton: false,
      loadingStatus: false,
      isSuccess: false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'shopsetting', 'users']),
    datashop: {
      get() {
        return this.$store.getters.shopsetting
      },
      set(newValue) {
        return this.$store.dispatch('setShopSetting', newValue)
      },
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    email() {
      if (this.users) {
        return this.users.email || '-'
      } else {
        return '-'
      }
    },
    stockMonitor: {
      get() {
        if (this.datashop.stockConfig) {
          return this.datashop.stockConfig.stockMonitor
        } else {
          return false
        }
      },
      set(newValue) {
        return newValue
      },
    },
    skuStock: {
      get() {
        if (this.datashop.emailNotification) {
          this.skuStockUpdate = this.datashop.emailNotification.skuStock
          return this.datashop.emailNotification.skuStock
        } else {
          return false
        }
      },
      set(newValue) {
        this.skuStockUpdate = newValue
      },
    },
    pluStock: {
      get() {
        if (this.datashop.emailNotification) {
          this.pluStockUpdate = this.datashop.emailNotification.pluStock
          return this.datashop.emailNotification.pluStock
        } else {
          return false
        }
      },
      set(newValue) {
        this.pluStockUpdate = newValue
      },
    },
    minStock: {
      get() {
        if (this.datashop.stockConfig) {
          this.minStockUpdate = this.datashop.stockConfig.minStock
          return this.datashop.stockConfig.minStock
        } else {
          return 0
        }
      },
      set(newValue) {
        this.minStockUpdate = newValue
      },
    },
  },
  methods: {
    updateStatus(status) {
      this.loadingStatus = true
      const uid = `${localStorage.getItem('shopsUid')}`
      let data = {
        stockConfig: {
          stockMonitor: status,
          minStock: Number(this.minStockUpdate),
        },
        objectId: this.shopObjectId,
      }

      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/Shop/updateconfig',
        data: data,
      })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch('getShopSetting')
            this.loadingStatus = false
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    updateData() {
      this.loadingButton = true
      const uid = `${localStorage.getItem('shopsUid')}`

      let stockdata = {
        ...this.datashop.stockConfig,
        ...{ minStock: Number(this.minStockUpdate) },
      }

      let data = {
        emailNotification: {
          pluStock: this.pluStockUpdate,
          skuStock: this.skuStockUpdate,
        },
        stockConfig: stockdata,
        objectId: this.shopObjectId,
      }

      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/Shop/updateconfig',
        data: data,
      })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch('getShopSetting')
            this.loadingButton = false
            this.isSuccess = true
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3abc98;
  background-color: #3abc98;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.1rem;
}
</style>
